import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import config from "../utils/config.js";

export default () => (
  <section className="ftco-intro bg-primary py-5">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6">
          <Link to="/contacts"><h2><FormattedMessage id="contacts.contact_us"/></h2></Link>
          <p>
          <FormattedMessage id="contacts.questions"/>
          </p>
        </div>
        <div className="col-md-5 text-md-right">
          <span className="contact-number">{config.contacts.phone}</span>
        </div>
      </div>
    </div>
  </section>
);
