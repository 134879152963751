import React from "react";
import Layout from "../components/Layout.jsx";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { parseXmlServices } from "../utils";
import { graphql } from "gatsby";
import backgroundImageSrc from '../images/home_background.jpg';
import CompanySection from "../components/CompanySection.jsx";
import ContactSection from "../components/ContactSection.jsx";
import * as animationStyles from "../styles/modules/animation.module.scss";

export default ({ data, location }) => {
  const style = {
    backgroundImage: `url('${backgroundImageSrc}')`,
  }
  const currentLocale = useIntl().locale;
  const services = parseXmlServices(data, currentLocale); 
  return (
  <Layout location={location}>
<section
    className="hero-wrap hero-wrap-2 home-section"
    style={style}
    data-stellar-background-ratio="0.5"
  >
    <div className="overlay"></div>
    <div className="container">
    <div className="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
          <div className={`col-md-6 ${animationStyles.slider_animation}`}>
          	<h2 className="subheading text-white"><FormattedMessage id="home.welcome"/></h2>
          	<h1><FormattedMessage id="home.presentation_title"/></h1>
            <p className="mb-4"><FormattedMessage id="home.patterns"/></p>
            <p><Link to="/services" className="btn btn-primary mr-md-4 py-2 px-4"><FormattedMessage id="services.read_more"/></Link></p>
          </div>
        </div>
    </div>
  </section>
    <ContactSection/>
    <CompanySection services={services}/>
  </Layout>
)};

export const query = graphql`
query {
  allServicesXml {
    edges {
      node {
        xmlChildren {
          children {
            name
            content
          }
          content
          name
        }
        name
      }
    }
  }
}
`;
