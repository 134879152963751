import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import aboutImg from "../images/about.jpg";
export default ({ services }) => {
    return (
    <section className="ftco-section ftco-no-pt ftco-no-pb bg-light">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-6 d-flex h-100 ">
          <img src={aboutImg} className="img-fluid d-none d-md-block" />
        </div>
        <div className="col-md-6 pl-md-5">
          <div className="row justify-content-start py-5">
            <div className="col-md-12 heading-section">
              <span className="subheading"><FormattedMessage id="company.who_we_are"/></span>
              <h2 className="mb-4"><FormattedMessage id="company.company_history"/></h2>
              <p>
              <FormattedMessage id="company.description"/>
              </p>
              <div className="services-wrap">
                {services.map((service) => (
                  <Link className="services-list" to={service.link}>{service.title}
                    <div className="btn-custom d-flex align-items-center justify-content-center">
                    <i className="fa fa-arrow-right text-white"></i>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)};
